import React from 'react';
import './App.css';
import "react-datepicker/dist/react-datepicker.css";
import Front from "./components/front/Front";
import RegistrateWindow from "./components/registrationWindow/RegistrationWindow";
import AuthorizationWindow from "./components/authorizationWindow/AuthorizationWindow";
import RestorePasswordWindow from "./components/restorePasswordWindow/RestorePasswordWindow";
import SettingsWindow from "./components/settingsWindow/SettingsWindow";
import UserContext from "./contexts/UserContext";
import * as ServerConnection from "./modules/ServerConnection";
import TabContext from "./contexts/TabContext";

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isRegistrationWindowOpen: false,
            isAuthorizationWindowOpen: false,
            isRestorePasswordWindowOpen: false,
            isSettingsWindowOpen: false,
            user: {
                email: "",
                userId: "",
                notifyByEmail: false,
            },

            tab: "actual",
        }
    }

    get userContext() {
        return {
            email: this.state.user.email,
            userId: this.state.user.userId,
            notifyByEmail: this.state.user.notifyByEmail,
            updateUser: this.updateUser.bind(this),
        }
    }

    get tabContext() {
        return {
            tab: this.state.tab,
            changeTab: this.changeTab.bind(this)
        }
    }


    render() {
        return (
            <div id="App" className="App">
                <UserContext.Provider value={this.userContext}>
                    {this.state.isRegistrationWindowOpen ?
                        <RegistrateWindow closeWindow={() => this.closeRegistrationWindow()}
                                          openAuthWindow={() => this.openAuthorizationWindow()}/> : null}
                    {this.state.isAuthorizationWindowOpen ?
                        <AuthorizationWindow closeWindow={() => {
                            this.closeAuthorizationWindow()
                        }}
                                             openRegWindow={() => this.openRegistrationWindow()}
                                             openRestorePassWindow={() => this.openRestorePasswordWindow()}/> : null}
                    {this.state.isRestorePasswordWindowOpen ?
                        <RestorePasswordWindow closeWindow={() => this.closeRestorePasswordWindow()}
                                               openAuthWindow={() => this.openAuthorizationWindow()}/> : null}
                    {this.state.isSettingsWindowOpen ? <SettingsWindow/> : null}

                    <TabContext.Provider value={this.tabContext}>
                        <Front/>
                    </TabContext.Provider>
                </UserContext.Provider>
            </div>
        );
    }

    componentDidMount() {
        this.authorize();

        setTimeout(()=>{
            document.getElementById("App").style.cssText = "";
        },100)
    }

    /**
     * Метод для автоматической авторизации
     */
    authorize() {
        if (!window.localStorage) {//Проверяем на отсутствие localStorage
            return this.openAuthorizationWindow()
        }

        let authCode = window.localStorage.getItem("authCode");//Получаем код авторизации

        if (!authCode) {//Если кода нет, то открывает окно авторизации
            return this.openAuthorizationWindow()
        }

        console.log("У нас есть код авторизации!")

        ServerConnection.once("user-auth-via-auth-code-response", (response) => {
            if (response.status === "error") {
                console.log("User Auth error: ", response.message, response.data);
                if (response.message === "USER_NOT_FOUND_ERROR") {
                    console.warn("Пользователь не найден!");
                }

                this.openAuthorizationWindow();
                return;
            }

            console.log("Success auth", response.data);

            this.updateUser(response.data.email, response.data.userId, response.data.notifyByEmail);
        })

        ServerConnection.emit("user-auth-via-auth-code", {
            authCode: authCode,
        })
    }

    updateUser(email, userId, notifyByEmail) {
        this.setState({
            user: {
                email: email,
                userId: userId,
                notifyByEmail: notifyByEmail
            }
        })
    }

    openRegistrationWindow() {
        this.setState({
            isRegistrationWindowOpen: true,
        })
    }

    closeRegistrationWindow() {
        this.setState({
            isRegistrationWindowOpen: false,
        })
    }

    openAuthorizationWindow() {
        this.setState({
            isAuthorizationWindowOpen: true,
        })
    }

    closeAuthorizationWindow() {
        this.setState({
            isAuthorizationWindowOpen: false,
        })
    }

    openRestorePasswordWindow() {
        this.setState({
            isRestorePasswordWindowOpen: true
        })
    }

    closeRestorePasswordWindow() {
        this.setState({
            isRestorePasswordWindowOpen: false
        })
    }

    openSettingsWindow() {
        this.setState({
            isSettingsWindowOpen: true
        })
    }

    closeSettingsWindow() {
        this.setState({
            isSettingsWindowOpen: false
        })
    }

    changeTab(tab) {
        if (["today","actual",  "tomorrow", "week", "completed"].findIndex(tabName => tab === tabName) >= 0) {
            this.setState({
                tab
            })
        }
    }




}

export default App;
