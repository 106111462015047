import React from "react";
import "./authorizationWindow.css"
import CustomInput from "../customInput/CustomInput";
import PropTypes from 'prop-types';
import * as ServerConnection from "../../modules/ServerConnection"
import UserContext from "../../contexts/UserContext";

class AuthorizationWindow extends React.Component {

    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
        }
    }

    render() {
        return (
            <div className="authorization-window">
                <div className="full-window-dark-background"></div>
                <div className="authorization-window_window">
                    <div className="authorization-window_window_title">Авторизация</div>
                    <CustomInput title="Эл. почта" value={this.state.email} onChange={(text) => {
                        this.setState({email: text});
                    }}/>
                    <CustomInput className="authorization-window_window_input-password" title="Пароль" type="password"
                                 value={this.state.password} onChange={(text) => {
                        this.setState({password: text});
                    }}/>



                    <button className="button green" onClick={()=>{this.authorize()}}>Войти</button>
                    <div onClick={()=>{this.openRegistrationWindow()}} className="authorization-window_window_go-to-reg-btn">У меня ещё нет аккаунта</div>
                </div>
            </div>
        )
    }

    /**
     * Метод для входа в аккаунт по логину и паролю
     */
    authorize() {
        ServerConnection.once("user-auth-via-email-response",(response)=>{
            if(response.status === "error"){
                console.log("User Auth error: ",response.message,response.data);
                if(response.message === "USER_NOT_FOUND_ERROR"){
                    console.warn("Пользователь не найден!");
                }

                return;
            }

            console.log("Success auth",response.data);

            this.context.updateUser(response.data.email, response.data.userId, response.data.notifyByEmail);

            if(window.localStorage){
                window.localStorage.setItem("authCode",response.data.authCode);
            }


            this.props.closeWindow();
        })

        ServerConnection.emit("user-auth-via-email", {
            email: this.state.email,
            password: this.state.password
        })
    }

    openRegistrationWindow(){
        this.props.closeWindow();
        this.props.openRegWindow()
    }

    openRestorePasswordWindow(){
        this.props.closeWindow();
        this.props.openRestorePassWindow();
    }

}

AuthorizationWindow.propTypes = {
    closeWindow: PropTypes.func.isRequired,
    openRegWindow: PropTypes.func.isRequired,
    openRestorePassWindow: PropTypes.func.isRequired,
};

export default AuthorizationWindow;


