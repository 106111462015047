import io from "socket.io-client"
console.log("connect to socket.io")
const socket = io("https://todos.dukentre.ru");

/**
 * Функция отправки данных на событие сервера
 * @param {string} eventName - название события
 * @param {object} data - данные для передачи
 */
export function emit(eventName,data) {
    console.log("emit")
    socket.emit(eventName,toJson(data));
}

/**
 * Функция для подписывания на постоянный приём события от сервера
 * @param {string} eventName - название события
 * @param {function(object)} callback - функция обратного вызова
 */
export function on(eventName,callback) {
    socket.on(eventName,(response)=>{
        try {
            callback(JSON.parse(response))
        }catch (e) {
            console.error("ERROR IN RESPONSE DATA:",response,"(",e+"",")")
        }

    })
}

/**
 * Функция для подписывания на одноразовый приём события от сервера
 * @param {string} eventName - название события
 * @param {function(object)} callback - функция обратного вызова
 */
export function once(eventName,callback) {
    socket.once(eventName,(response)=>{
        try {
            callback(JSON.parse(response))
        }catch (e) {
            console.error("ERROR IN RESPONSE DATA:",response,"(",e+"",")")
        }

    })
}


function toJson(data) {
    for(let key of Object.keys(data)){
        if(typeof data[key] !== "string")
            data[key] = JSON.stringify(data[key])
    }
    return JSON.stringify(data);
}
