import React from "react";
import "./settingsWindow.css"

const defaultPhotoPath = "/default-photo.png";


function SettingsWindow() {


    return (
        <div className="settings-window">
            <div className="full-window-dark-background"></div>
            <div className="settings-window_window">
                <div className="settings-window_window_title">Настройки</div>

                <div className="settings-window_window_user">

                    <img alt="you" src={defaultPhotoPath} className="settings-window_window_user_photo"/>

                    <button className="button green">Зарегистрироваться</button>



                </div>


                <div className="settings-window_window_checkbox"><input id="settings-window_notify-by-email" type="checkbox"/><label
                    htmlFor="settings-window_notify-by-email"
                    className="settings-window_window_notify-by-email">Я разрешаю присылать на указанную почту
                    оповещения о текущих задачах</label>
                </div>

                <button className="button green">Сохранить</button>



            </div>
        </div>
    )
}

export default SettingsWindow;
