import React from "react";
import "./taskMenu.css"
import TabContext from "../../contexts/TabContext";
import PropsTypes from "prop-types";

class TaskMenu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }

    render() {
        return (
            <TabContext.Consumer>
                {tab => (
                    <div className="task-menu">
                        <div onClick={()=>this.props.editTask()} className="task-menu_button">
                            Редактировать
                        </div>
                        <div className="h-break"></div>
                        <div onClick={()=>this.props.deleteTask()} className="task-menu_button task-menu_button-remove">
                            Удалить
                        </div>
                    </div>
                )}
            </TabContext.Consumer>
        )
    }





}

TaskMenu.propsTypes = {
    editTask: PropsTypes.func.isRequired,
    deleteTask: PropsTypes.func.isRequired,
}

export default TaskMenu;
