import React from "react";
import "./customInput.css";



function CustomInput(params) {

    function update(event) {
        if (typeof params.onChange === "function") {
            params.onChange(event.target.value);
        }
    }

    return (
        <div className={"custom-input "+ (params.className || "")}>
            <div className="custom-input_title">{params.title}</div>
            <input className="custom-input_input" value={params.value} onChange={update} type={params.type ? params.type : "text"} placeholder={params.placeholder}/>
        </div>
    )
}

export default CustomInput;
