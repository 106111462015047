import React from "react";
import "./restorePasswordWindow.css"
import CustomInput from "../customInput/CustomInput";
import PropTypes from "prop-types"


class RestorePasswordWindow extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            email: "",
        }
    }


    render() {
        return (
            <div className="restore-password-window">
                <div className="full-window-dark-background"></div>
                <div className="restore-password-window_window">
                    <div className="restore-password-window_window_title">Востановление пароля</div>
                    <CustomInput title="Эл. почта" value={this.state.email} onChange={(text) => {
                        this.setState({email:text})
                    }}/>



                    <button className="button green">Далее</button>
                    <div onClick={()=>this.openAuthorizationWindow()} className="restore-password-window_window_go-to-login-btn">Назад к авторизации</div>
                </div>
            </div>
        )
    }

    openAuthorizationWindow(){
        this.props.closeWindow();
        this.props.openAuthWindow();
    }
}



RestorePasswordWindow.propTypes = {
    closeWindow: PropTypes.func.isRequired,
    openAuthWindow: PropTypes.func.isRequired,
}

export default RestorePasswordWindow;
