import React from "react";
import "./taskCard.css"
import PropsTypes from "prop-types";

class TaskCard extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            isCompleted: this.props.isCompletedProp,
        }

       /* this.setState({
            isCompleted: this.props.isCompletedProp
        })*/
    }

    render() {
        return (

            <div className="task-card">
                <div className="wrapper">
                    <div className="task-card_left">
                        <div onClick={()=>{this.props.changeIsCompleted(this.props.id,!this.state.isCompleted)}} className="task-card_left_checkbox">
                            <input checked={this.state.isCompleted}
                                   onChange={event => this.setState({
                                       isCompleted: event.target.checked,
                                   })}
                                   id={this.props.id}
                                   type="checkbox"/>
                            <label htmlFor={this.props.id}></label>
                        </div>
                        <div onClick={()=>{this.props.changeIsCompleted(this.props.id,!this.state.isCompleted)}} className="task-card_left_title">
                            {this.props.title}
                        </div>
                    </div>
                    <div onClick={this.props.openMenu} style={{
                        "mask": 'url("/triple-dots.png") center center/contain',
                        "WebkitMask": 'url("/triple-dots.png") center center/contain'
                    }} className="task-card_menu-btn"> </div>
                </div>
                <div className="task-card_date">{this.dateToFormat(this.props.date)}</div>
            </div>

        )
    }

    dateToFormat(dateNumber){
        let date = new Date(+dateNumber);

        return `${date.getFullYear()}.${date.getMonth()+1 < 10 ? "0" + (date.getMonth()+1) : date.getMonth()+1}.${date.getDate() < 10 ? "0" + date.getDate(): date.getDate()} ${date.getHours() < 10 ? "0" + date.getHours(): date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes(): date.getMinutes()}`
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.isCompletedProp !== this.props.isCompletedProp) {
            this.setState({
                isCompleted: this.props.isCompletedProp
            })
        }
    }

}

TaskCard.propsTypes = {
    id: PropsTypes.string.isRequired,
    title: PropsTypes.string.isRequired,
    date: PropsTypes.number.isRequired,
    openMenu: PropsTypes.func.isRequired,
    changeIsCompleted: PropsTypes.func.isRequired,
    isCompletedProp: PropsTypes.bool.isRequired,
}

export default TaskCard;
