import React from "react";
import "./sidebar.css"
import Profile from "../profile/Profile";
import TabContext from "../../contexts/TabContext";

class Sidebar extends React.Component {

    render() {
        return (
            <TabContext.Consumer>
                {tab => (
                    <div className="sidebar">
                        <Profile todayTasks={3}/>
                        <div className="h-break"></div>
                        <div onClick={()=>{tab.changeTab("actual")}}  className={"tab " + (tab.tab === "actual" ? "active" : "")}>
                            <div className="tab_title">Актуальные</div>
                        </div>
                        <div onClick={()=>{tab.changeTab("today")}}  className={"tab " + (tab.tab === "today" ? "active" : "")}>
                            <div className="tab_title">На сегодня</div>
                        </div>
                        <div onClick={()=>{tab.changeTab("tomorrow")}} className={"tab " + (tab.tab === "tomorrow" ? "active" : "")}>
                            <div className="tab_title">На завтра</div>
                        </div>
                        <div onClick={()=>{tab.changeTab("week")}} className={"tab " + (tab.tab === "week" ? "active" : "")}>
                            <div className="tab_title">На этой неделе</div>
                        </div>
                        <div onClick={()=>{tab.changeTab("completed")}} className={"tab " + (tab.tab === "completed" ? "active" : "")}>
                            <div className="tab_title">Завершенные</div>
                        </div>
                        <ins className="adsbygoogle"
                             style={{"display":"block"}}
                             data-ad-client="ca-pub-4322023274999577"
                             data-ad-slot="9423118081"
                             data-ad-format="auto"
                             data-full-width-responsive="true"></ins>
                    </div>
                )}
            </TabContext.Consumer>
        )
    }

    componentDidMount () {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }


}

export default Sidebar;
