import React from 'react';
import "./front.css"
import Sidebar from "../sidebar/Sidebar";
import UserContext from "../../contexts/UserContext";
import TasksList from "../tasksList/TasksList";

class Front extends React.Component{

    static contextType = UserContext;

    render() {
        return(
            <div className="front">
                <Sidebar/>
                <TasksList/>
            </div>
        );
    }

    componentDidMount() {

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log(this.context)
    }
}

export default Front;
