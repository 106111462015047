import React from "react";
import "./tasksList.css"
import TabContext from "../../contexts/TabContext";
import TaskCreator from "../taskCreator/TaskCreator";
import * as ServerConnection from "../../modules/ServerConnection"
import UserContext from "../../contexts/UserContext";
import TaskCard from "../taskCard/TaskCard";
import money from "../../modules/SpecialLogger";
import TaskMenu from "../taskMenu/TaskMenu";

class TasksList extends React.Component {

    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.state = {
            todayTasks: [],
            tomorrowTasks: [],
            weekTasks: [],
            completedTasks: [],
            actualTasks: [],

            tasks: [],

            oldUserContext: {},

            oldTab: {tab: "actual"},
            tab: {tab: "actual"},

            adIntegrate: 0,

            editTask: null,
        }

        this.state.oldUserContext = this.context;
    }

    render() {
        return (
            <TabContext.Consumer>
                {tab => {
                    this.state.tab = tab;
                    return (
                        <div className="tasks-list">
                            <div className="tasks-list_title">{this.title(tab.tab)}</div>
                            <TaskCreator editTask={this.state.editTask} stopEditTask={this.stopEditTask.bind(this)}/>
                            {this.state.tasks.map((task, index) => {
                                if (task.showMenu) {
                                    return (
                                        <div key={task.id} className="block">
                                            <TaskCard isCompletedProp={task.isCompleted}
                                                      changeIsCompleted={this.changeIsCompleted.bind(this)}
                                                      openMenu={this.openMenu.bind(this, index)} key={task.id}
                                                      title={task.title} date={+task.date} id={task.id}/>
                                            <TaskMenu editTask={this.editTask.bind(this, index)}
                                                      deleteTask={this.deleteTask.bind(this, index)}/>

                                        </div>
                                    )
                                } else {
                                    return (
                                        <div key={task.id} className="block">
                                            <TaskCard isCompletedProp={task.isCompleted}
                                                      changeIsCompleted={this.changeIsCompleted.bind(this)}
                                                      openMenu={this.openMenu.bind(this, index)} key={task.id}
                                                      title={task.title} date={+task.date} id={task.id}/>
                                        </div>
                                    )
                                }

                            })}
                        </div>
                    )
                }}
            </TabContext.Consumer>
        )
    }

    componentDidMount() {
        this.getTodayTasks();

        this.listenAddTask();
        this.listenDeleteTask();
        this.listenEditTask();
        this.listenChangeIsCompleted();
    }


    componentDidUpdate(prevProps, prevState) {
        if (this.context.userId && this.state.oldUserContext?.userId !== this.context?.userId) {
            getTasks.call(this);
            this.state.oldUserContext = this.context
        }


        console.log(this.state.tab.tab, this.state.oldTab.tab)
        if (this.context.userId && this.state.oldTab.tab !== this.state.tab.tab) {
            getTasks.call(this)
            this.state.oldTab = this.state.tab;
        }


        function getTasks() {
            switch (this.state.tab.tab) {
                default:
                case "actual":
                    this.getActualTasks(true);
                    break;
                case "today":
                    this.getTodayTasks(true);
                    break;
                case "tomorrow":
                    this.getTomorrowTasks(true);
                    break;
                case "week":
                    this.getWeekTasks(true);
                    break;
                case "completed":
                    this.getCompletedTasks(true);
                    break;
            }
        }


    }

    title(tab) {
        switch (tab) {
            default:
            case "today":
                return "Задачи на сегодня";
            case "tomorrow":
                return "Задачи на завтра";
            case "week":
                return "Задачи на эту неделю";
            case "completed":
                return "Завершенные задачи"

        }
    }

    getTodayTasks(isLoadNew = false) {
        let dateStart = new Date()
        dateStart.setHours(0, 0, 0, 0)
        let dateEnd = new Date()
        dateEnd.setHours(23, 59, 59, 999)

        this.getTasks(
            dateStart,
            dateEnd,
            "todayTasks",
            isLoadNew
        )
    }

    getWeekTasks(isLoadNew = false) {
        let dateStart = new Date()
        dateStart.setDate(dateStart.getDate() - dateStart.getDay());
        dateStart.setHours(0, 0, 0, 0)
        let dateEnd = new Date()
        dateEnd.setDate(dateStart.getDate() + (7 - dateStart.getDay()));
        dateEnd.setHours(23, 59, 59, 999)

        this.getTasks(
            dateStart,
            dateEnd,
            "weekTasks",
            isLoadNew
        )
    }

    getTomorrowTasks(isLoadNew = false) {
        let dateStart = new Date()
        dateStart.setDate(dateStart.getDate() + 1)
        dateStart.setHours(0, 0, 0, 0)
        let dateEnd = new Date()
        dateEnd.setDate(dateEnd.getDate() + 1)
        dateEnd.setHours(23, 59, 59, 999)

        this.getTasks(
            dateStart,
            dateEnd,
            "tomorrowTasks",
            isLoadNew
        )
    }

    getActualTasks(isLoadNew = false) {
        this.getTasks(
            0,
            +new Date(9999, 12, 39, 23, 59, 59, 9999),
            "actualTasks",
            isLoadNew
        )
    }

    getCompletedTasks(isLoadNew = false) {
        this.getTasks(
            0,
            +new Date(9999, 12, 39, 23, 59, 59, 9999),
            "completedTasks",
            isLoadNew,
            true,
        )
    }

    getTasks(dateStart, dateEnd, taskName, isLoadNew = false, isCompleted = false) {
        ServerConnection.once("user-task-get-response", (response) => {
            if (response.status === "error") return;

            let newState = {
                tasks: [...response.data.tasks]
            }

            if (taskName) {
                newState[taskName] = [...response.data.tasks]
            }

            if (!isLoadNew) {
                newState.tasks.unshift(...this.state.tasks);
            }

            this.setState(newState)
            this.insertGoogleAds();
        })

        ServerConnection.emit("user-task-get", {
            fromDate: +dateStart,
            toDate: +dateEnd,
            from: isLoadNew ? 0 : this.state.tasks.length,
            limit: 40,
            isCompleted,
        })
    }

    listenAddTask() {
        ServerConnection.on("add-task", (response) => {
            console.log(response)
            if (response.status === 'error') return;

            this.setState({
                tasks: [...this.state.tasks, response.data]
            })

        })
    }


    insertGoogleAds() {
        setTimeout(() => {
            for (let i = 0; i <= +((this.state.tasks.length / 3).toFixed(0)); i++) {
                try {
                    (window.adsbygoogle = window.adsbygoogle || []).push({})
                } catch (e) {
                    money()
                }
            }
        }, 1000 * 1.5)
    }

    openMenu(taskIndex) {
        let task = this.state.tasks[taskIndex];
        task.showMenu = true;
        this.state.tasks.splice(taskIndex, 1, task);
        this.setState({
            tasks: this.state.tasks
        })
        console.log("openMenu", taskIndex)
    }

    deleteTask(taskIndex) {
        let task = this.state.tasks[taskIndex];
        task.showMenu = false;
        this.state.tasks.splice(taskIndex, 1, task);
        this.setState({
            tasks: this.state.tasks
        })
        ServerConnection.emit("user-task-delete", {
            taskId: task.id,
        })
        console.log(taskIndex)
    }

    editTask(taskIndex) {
        let task = this.state.tasks[taskIndex];
        task.showMenu = false;
        this.state.tasks.splice(taskIndex, 1, task);
        this.setState({
            tasks: this.state.tasks
        })
        this.setState({editTask: task});
        console.log(taskIndex)
    }

    stopEditTask() {
        this.setState({editTask: null});
    }

    listenDeleteTask() {
        ServerConnection.on("remove-task", (responce) => {
            if (!responce.error) {
                let taskIndex = this.state.tasks.findIndex(task => task.id === responce.data)
                this.state.tasks.splice(taskIndex, 1);
                this.setState({
                    tasks: this.state.tasks
                })
            }
        })

    }

    listenEditTask() {
        ServerConnection.on("edit-task", (responce) => {
            console.log(responce)
            if (!responce.error) {
                let task = responce.data;
                let taskIndex = this.state.tasks.findIndex(task => task.id === responce.data.id)
                console.log(task, taskIndex)
                this.state.tasks.splice(taskIndex, 1, task);
                this.setState({
                    tasks: this.state.tasks
                })
            }
        })
    }

    changeIsCompleted(taskId, isCompleted) {
        ServerConnection.emit("user-task-change-is-completed", {
            taskId,
            isCompleted
        })
    }

    listenChangeIsCompleted(){
        ServerConnection.on("update-is-completed-task",responce =>{
            if (!responce.error) {
                let taskIndex = this.state.tasks.findIndex(task => task.id === responce.data.id)
                this.state.tasks.splice(taskIndex, 1, );
                this.setState({
                    tasks: this.state.tasks
                })
            }
        })
    }

}

export default TasksList;
