import React from "react";
import "./profile.css";
import UserContext from "../../contexts/UserContext";

const defaultPhotoPath = "/default-photo.png";

class Profile extends React.Component{

    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        return (
            <div className="profile">
                <img alt="you" src={this.context.photo ? this.context.photo : defaultPhotoPath} className="profile_photo"/>
                <div className="profile_info">
                    <div className="profile_info_name">
                        {this.context.email}
                    </div>
                </div>
            </div>
        )
    }


}

export default Profile;
