import React from "react";
import "./taskCreator.css"
import TabContext from "../../contexts/TabContext";
import CustomInput from "../customInput/CustomInput";
import DatePicker from "react-datepicker";
import * as ServerConnection from "../../modules/ServerConnection"

class TaskCreator extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            date: new Date(),
            title: "",
            lastEditTask:null,
        }


    }

    render() {
        return (
            <TabContext.Consumer>
                {tab => (
                    <div className="task-creator">
                        <CustomInput title="Задача" value={this.state.title}
                        onChange={(text)=>this.setState({
                            title:text
                        })}/>
                        <div className="task-creator_bottom">
                            <div className="task-creator_bottom_date">
                                <div className="task-creator_bottom_date_title">
                                    Дата и время:
                                </div>

                                <DatePicker
                                    selected={this.state.date}
                                    onChange={(date) => this.setState({date})}
                                    showTimeSelect
                                    filterDate={this.filterPassedTime}
                                    filterTime={this.filterPassedTime}
                                    timeFormat="HH:mm"
                                    timeIntervals={5}
                                    dateFormat="MMMM d, yyyy HH:mm"
                                    timeCaption="Время"
                                />
                            </div>
                            <div onClick={()=>this.addTask()} className="button green">{
                                this.props.editTask ? "Изменить" : "Добавить"
                            }</div>
                        </div>
                    </div>
                )}
            </TabContext.Consumer>
        )
    }


    filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate < selectedDate;
    };


    componentDidUpdate(prevProps, prevState) {
        if(this.props.editTask && this.props.editTask !== this.state.lastEditTask){
            this.setState({
                title:this.props.editTask.title,
                date: new Date(this.props.editTask.date),
                lastEditTask: this.props.editTask
            })
        }
    }

    addTask(){
        console.log(this.state.title.length)
        if(this.state.title.length >= 2){
            if(this.props.editTask){
                ServerConnection.emit("user-task-edit",{
                    taskId:this.props.editTask.id,
                    title:this.state.title,
                    date:+this.state.date
                })
                console.log("Редактируем таск")
                this.setState({
                    title:"",
                    date:new Date(),
                    lastEditTask:null,
                })
                this.props.stopEditTask()
            }else{
                ServerConnection.emit("user-task-add",{
                    title:this.state.title,
                    date:+this.state.date
                })
                this.setState({
                    title:"",
                })
            }

        }
    }

}

export default TaskCreator;
