import React from "react";
import "./registrateWindow.css"
import CustomInput from "../customInput/CustomInput";
import PropsTypes from "prop-types";
import * as ServerConnection from "../../modules/ServerConnection"
import UserContext from "../../contexts/UserContext";

class RegistrateWindow extends React.Component {

    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            repeatPassword: "",
            notifyByEmail: false,
        }
    }

    render() {
        return (
            <div className="registration-window">
                <div className="full-window-dark-background"></div>
                <div className="registration-window_window">
                    <div className="registration-window_window_title">Регистрация</div>
                    <CustomInput title="Эл. почта" value={this.state.email} onChange={(text) => {
                        this.setState({email: text})
                    }}/>
                    <CustomInput title="Пароль" type="password" value={this.state.password} onChange={(text) => {
                        this.setState({password: text})
                    }}/>
                    <CustomInput title="Повторите пароль" type="password" value={this.state.repeatPassword} onChange={(text) => {
                        this.setState({repeatPassword: text})
                    }}/>

                    <button onClick={() => this.registrate()} className="button green">Зарегистрироваться</button>
                    <div onClick={() => this.openAuthorizationWindow()}
                         className="registration-window_window_go-to-login-btn">У меня уже есть аккаунт
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Метод регистрации пользователя
     */
    registrate() {
        if(this.state.email && this.state.password.length > 3 && this.state.repeatPassword === this.state.password) {
            ServerConnection.once("user-auth-registrate-response", (response) => {
                if (response.status === "error") {
                    console.error("User registrate error", response.message, response.data)
                    return
                }

                console.log("Registration success!", response.data)

                this.context.updateUser(response.data.email, response.data.userId, response.data.notifyByEmail);

                if (window.localStorage) {
                    window.localStorage.setItem("authCode", response.data.authCode);
                }


                this.props.closeWindow();
            })

            ServerConnection.emit("user-auth-registrate", {
                email: this.state.email,
                password: this.state.password,
                notifyByEmail: this.state.notifyByEmail,
            })
        }
    }


    changeNotifyByEmailCheckbox(event) {
        this.setState({
            notifyByEmail: event.target.checked,
        })
    }

    openAuthorizationWindow() {
        this.props.closeWindow();
        this.props.openAuthWindow();
    }
}

RegistrateWindow.propsTypes = {
    closeWindow: PropsTypes.func.isRequired,
    openAuthWindow: PropsTypes.func.isRequired,
}

export default RegistrateWindow;
